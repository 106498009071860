<template>
  <el-drawer v-model="drawer" :close-on-click-modal="false" size="900px" :title="comInfo.name + '-账务质检详情'">
    <div v-loading="loading">
      <div class="top_zj" v-if="isShow">
        <div class="top_content">
          <div>
            <div>
              综合评分：<span class="fs">{{ comInfo.zjFs }}</span>分
            </div>
            <!-- <div>最后检测时间：xxxxxx</div> -->
          </div>
          <div>
            <el-button type="primary" size="small" @click="qualityFresh">重新检测
            </el-button>
          </div>
        </div>
      </div>
      <el-scrollbar :maxHeight="contentStyleObj">
        <div class="main">
          <div v-for="item in checkData" :key="item.name">
            <div v-if="item.value && item.value.length">
              <div class="hint">{{ item.name }}</div>
              <el-table :data="item.value" border>
                <el-table-column prop="name" label="检查项"> </el-table-column>
                <el-table-column label="检查结果">
                  <template #default="scope">
                    <div class="item_icon">
                      <i class="iconfont icon-tongzhi" :class="filterIcon(scope.row.value, scope.row.kf)"></i>
                      {{ filterText(scope.row.value, scope.row.kf) }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </el-drawer>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, computed } from "vue";
import { qualityCom } from "@/api/company";
const { proxy } = getCurrentInstance();
const emit = defineEmits();
const comInfo = ref({});
const drawer = ref(false);
const period = ref("");
const loading = ref(false);
const qd = ref([]);
const pz = ref([]);
const subject = ref([]);
const tax = ref([]);
const fee = ref([]);
const jy = ref([]);
const contentStyleObj = ref({});
const isShow = ref(true)
const checkData = computed(() => {
  let arr = [
    {
      name: '清单检查',
      value: qd.value
    },
    {
      name: '凭证检查',
      value: pz.value
    },
    {
      name: '科目检查',
      value: subject.value
    },
    {
      name: '报表检查',
      value: tax.value
    },
    {
      name: '费用指标',
      value: fee.value
    },
    {
      name: '企业经营指标',
      value: jy.value
    },
  ]
  return arr
})
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(165);
});
const init = (row, date, child) => {
  drawer.value = true;
  comInfo.value = row;
  period.value = date;
  isShow.value = child ? false : true;
  if (child) {
    parseValue(child);
  } else {
    parseValue(comInfo.value.zjLog);
  }
};
const parseValue = (info) => {
  qd.value = [];
  pz.value = [];
  subject.value = [];
  tax.value = [];
  fee.value = [];
  jy.value = [];
  info?.forEach((v) => {
    const child = v.Child || [];
    if (Array.isArray(child)) {
      switch (v.Name) {
        case "清单检查":
          processChild(child, qd.value);
          break;
        case "凭证检查":
          processChild(child, pz.value);
          break;
        case "科目检查":
          processChild(child, subject.value);
          break;
        case "报表检查":
          processChild(child, tax.value);
          break;
        case "费用指标":
          processChild(child, fee.value);
          break;
        case "企业经营指标":
          processChild(child, jy.value);
          break;
        default:
          break;
      }
    }
  });
};
const processChild = (child, targetArray) => {
  child.forEach((k) => {
    let demo = k.split("#");
    targetArray.push({
      name: demo[0],
      value: demo[1],
      kf: demo[2]
    });
  });
};
const qualityFresh = () => {
  loading.value = true;
  let param = {
    comIds: [comInfo.value.id],
    period: period.value,
  };
  qualityCom(param).then((res) => {
    if (res.data.msg == "success") {
      loading.value = false;
      emit("success");
      proxy.$qzfMessage("已重新检测");
      comInfo.value.zjFs = res.data.data.fs;
      let arr = JSON.parse(res.data.data.info);
      parseValue(arr);
    }
  });
};
const filterIcon = (type, flag) => {
  if (type.match("错误")) {
    return "sbyc2";
  } else if (type.match("提示")) {
    return "yczt";
  } else {
    return "sbcg2";
  }
  // if (type.match("错误")) {
  //   return flag && flag === '扣分' ? 'sbyc2' : flag ? 'yczt' : 'sbyc2';
  // } else if (type.match("提示")) {
  //   return flag && flag === '扣分' ? 'sbyc2' : flag ? 'yczt' : 'yczt';
  // } else {
  //   return "sbcg2";
  // }
};
const filterText = (text) => {
  if (text.includes("：")) {
    return text.substring(text.indexOf("：") + 1);
  }
  return text;
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.top_zj {
  padding: 10px 20px 20px;
  border-bottom: 1px solid #efefef;
  font-size: 14px;

  .top_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.main {
  padding: 0 20px 10px;
}

.iconfont {
  font-size: 13px;
  margin-right: 4px;
}

.hint {
  color: var(--themeColor, #17a2b8);
  margin: 20px 0 10px;
  font-size: 14px;
}

.fs {
  margin-right: 3px;
  font-weight: 600;
  color: var(--themeColor, #17a2b8);
}
</style>
